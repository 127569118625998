import styled from "styled-components"

export const H1 = styled.h1`
    font-size: 55rem;
    font-family: "Gilroy-Bold", sans-serif;
    color: ${({ theme }) => theme.text.dark};
    font-weight: unset;
    margin: 0;

    span {
        background: -webkit-linear-gradient(#60a5fa, #818cf8);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

export const H2 = styled.h2`
    font-weight: normal;
    font-size: 20rem;
    color: #374151;
    line-height: 35rem;
`

export const H3 = styled.h3`
    font-weight: normal;
    font-size: 18rem;
    color: ${({ theme }) => theme.text.normal};
    font-family: "Gilroy-SemiBold", sans-serif;
    margin-bottom: 10rem;
`

export const PreSection = styled.h4`
    color: ${({ theme }) => theme.primary};
    font-family: "Gilroy-Bold", sans-serif;
    font-size: 20rem;
`

export const FormLabel = styled.label`
    font-weight: normal;
    font-size: 14rem;
    color: ${({ theme }) => theme.text.normal};
    font-family: "Gilroy-SemiBold", sans-serif;
    margin: 5rem 0;
`

export const P = styled.p`
    margin: 10rem 0;
    line-height: 30rem;
    font-size: 18rem;
    color: #64748b;
    text-rendering: optimizeLegibility;

    sup {
        line-height: 0;
        font-size: 12rem;
    }
`
