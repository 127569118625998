import React, { ReactNode } from "react"
import styled from "styled-components"
import { H1, H2 } from "../Ui/Typography/Typography"
import { Link } from "gatsby"
import { TextButton } from "../Ui/TextButton/TextButton"

const Container = styled.div`
    display: flex;
    align-items: center;
    margin: 20rem 0;
    justify-content: space-between;

    > div {
        display: flex;

        &.left {
            flex-direction: column;

            .bread-crumbs {
                display: flex;
                align-items: center;
                gap: 5rem;
                margin-bottom: 4rem;
                flex-wrap: wrap;

                > * {
                    display: flex;
                    align-items: center;
                    color: ${({ theme }) => theme.text.lighter};
                }
            }

            h1 {
                font-size: 30rem;
                color: ${({ theme }) => theme.text.normal};
            }

            h2 {
                font-size: 18rem;
            }

            h1,
            h2 {
                margin: 0;
            }
        }

        &.right {
            justify-content: flex-end;
            gap: 10rem;
        }
    }
`

export type BreadCrumb = {
    to?: string
    title: string
}

interface PageHeaderProps {
    variant?: "basic"
    h1?: JSX.Element | string
    h2?: JSX.Element | string
    breadCrumbs?: Array<BreadCrumb>
    className?: string
    actionButtons?: Array<ReactNode>
}

export const PageHeader = ({
    variant = "basic",
    className = "",
    h1,
    h2,
    breadCrumbs,
    actionButtons = [],
}: PageHeaderProps) => {
    return (
        <Container className={className}>
            <div className={"left"}>
                {breadCrumbs && (
                    <div className={"bread-crumbs"}>
                        {breadCrumbs.map((b, i) => {
                            return React.createElement(b.to ? Link : "div", {
                                key: `${i}-${b.title}`,
                                to: `${b.to}`,
                                tabIndex: -1,
                                children: (
                                    <>
                                        <TextButton
                                            color={"default"}
                                            disabled={!b.to}
                                        >
                                            {b.title}
                                        </TextButton>
                                        {i !== breadCrumbs.length - 1 && (
                                            <Chevron />
                                        )}
                                    </>
                                ),
                            })
                        })}
                    </div>
                )}
                {h1 && <H1>{h1}</H1>}
                {h2 && <H2>{h2}</H2>}
            </div>
            <div className={"right"}>{actionButtons.map((a) => a)}</div>
        </Container>
    )
}

const Chevron = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="currentColor"
            className={"bottom-link-chevron"}
        >
            <path d="M9.29 6.71c-.39.39-.39 1.02 0 1.41L13.17 12l-3.88 3.88c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0l4.59-4.59c.39-.39.39-1.02 0-1.41L10.7 6.7c-.38-.38-1.02-.38-1.41.01z" />
        </svg>
    )
}
